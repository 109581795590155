var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-product"},[_c('div',[_c('ai-breadcrumb'),_c('div',{staticClass:"new-button"},[_c('guide',{staticClass:"guide-button"})],1)],1),_c('div',{staticClass:"toolbar"},[_c('el-form',{staticClass:"search-form",attrs:{"inline":true,"size":"small"}},[_c('el-form-item',{staticClass:"search-form-gutter",attrs:{"label":_vm.$t('analysis.platform')}},[_c('el-select',{staticStyle:{"width":"88px"},attrs:{"filterable":""},on:{"change":_vm.changePlatform},model:{value:(_vm.currentPlatform),callback:function ($$v) {_vm.currentPlatform=$$v},expression:"currentPlatform"}},_vm._l((_vm.platformList),function(item){return _c('el-option',{key:item.key,attrs:{"label":item.value,"value":item.key}})}),1)],1),_c('el-form-item',{staticClass:"search-form-gutter",attrs:{"label":_vm.$t('analysis.date')}},[_c('el-select',{staticStyle:{"width":"128px"},attrs:{"filterable":""},model:{value:(_vm.currentDate),callback:function ($$v) {_vm.currentDate=$$v},expression:"currentDate"}},_vm._l((_vm.dateList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{staticClass:"search-form-gutter",attrs:{"label":_vm.$t('common.brand')}},[_c('el-select',{staticStyle:{"width":"128px"},attrs:{"filterable":""},model:{value:(_vm.currentBrandId),callback:function ($$v) {_vm.currentBrandId=$$v},expression:"currentBrandId"}},_vm._l((_vm.brandList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{staticClass:"search-form-gutter",attrs:{"label":_vm.$t('productDetail.detail')}},[_c('el-select',{staticStyle:{"width":"128px"},attrs:{"filterable":""},model:{value:(_vm.currentKey),callback:function ($$v) {_vm.currentKey=$$v},expression:"currentKey"}},_vm._l((_vm.keyList),function(item){return _c('el-option',{key:item,attrs:{"label":_vm.$t(
                'analysisDetail.' +
                  item.replace(item[0], item[0].toLowerCase())
              ),"value":item}})}),1)],1),_c('el-form-item',{staticClass:"search-form-gutter",attrs:{"label":_vm.$t('productDetail.value')}},[_c('el-select',{staticStyle:{"width":"128px"},attrs:{"filterable":""},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},_vm._l((_vm.valueList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.displayValue,"value":item.value}})}),1)],1)],1),_c('div',{staticClass:"download-btn"},[_c('el-button',{staticClass:"excel-dl-btn",attrs:{"disabled":!_vm.currentDate || !_vm.currentBrandId || !_vm.currentKey || !_vm.currentValue,"size":"small","icon":"el-icon-download"},on:{"click":_vm.downloadAll}},[_vm._v(_vm._s(_vm.$t("analysis.downloadAll")))])],1)],1),_c('div',{staticClass:"data-content"},[(_vm.loading)?_c('p',{staticClass:"data-loading"},[_c('i',{staticClass:"el-icon-loading"}),_vm._v(" "+_vm._s(_vm.$t("common.loading"))+"... ")]):(!_vm.tableData || _vm.tableData.length === 0)?_c('p',{staticClass:"data-loading"},[_vm._v(" "+_vm._s(_vm.$t("common.noMoreData"))+" ")]):_c('el-row',{attrs:{"gutter":10}},_vm._l((_vm.tableData),function(item){return _c('el-col',{key:item.key,staticClass:"data-block",attrs:{"lg":{ span: '5-24' }}},[_c('div',{staticClass:"data-block-content"},[_c('el-image',{staticClass:"content-image",attrs:{"src":item.url,"preview-src-list":[item.url],"fit":"contain"}}),_c('div',[_c('el-row',{staticClass:"content-line"},[_c('router-link',{attrs:{"target":"_blank","to":{
                  path: '/services/analysis/style-search',
                  query: { q: item.key, platform: _vm.currentPlatform },
                }}},[_c('el-link',{staticClass:"content-link",attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.$t("productDetail.detailSearch")))])],1)],1),_c('el-row',{staticClass:"content-line"},[_c('span',{staticClass:"content-label"},[_vm._v(_vm._s(_vm.$t("productDetail.newValue")))]),_vm._v(" "+_vm._s(item.value)+" ")]),_c('el-row',{staticClass:"content-line"},[_c('span',{staticClass:"content-label"},[_vm._v(_vm._s(_vm.$t("productDetail.originalValue")))]),_vm._v(" "+_vm._s(item.originalValue)+" ")]),_c('el-row',{staticClass:"content-line"},[_c('span',{staticClass:"content-label"},[_vm._v(_vm._s(_vm.$t("productDetail.item")))]),_vm._v(" "+_vm._s(item.item)+" ")]),_c('el-row',{staticClass:"content-line"},[_c('span',{staticClass:"content-label"},[_vm._v(_vm._s(_vm.$t("productDetail.saleQty")))]),_vm._v(" "+_vm._s(item.saleQty)+" ")]),_c('el-row',{staticClass:"content-line"},[_c('span',{staticClass:"content-label"},[_vm._v(_vm._s(_vm.$t("productDetail.listPrice")))]),_vm._v(" ￥"+_vm._s(item.listPrice)+" ")]),_c('el-row',{staticClass:"content-line"},[_c('span',{staticClass:"content-label"},[_vm._v(_vm._s(_vm.$t("productDetail.salePrice")))]),_vm._v(" ￥"+_vm._s(item.salePrice)+" ")])],1)],1)])}),1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total > 0),expression:"total > 0"}],attrs:{"total":_vm.total,"offset":_vm.listQuery.offset,"limit":_vm.listQuery.limit,"layout":"sizes, prev, pager, next, total "},on:{"update:offset":function($event){return _vm.$set(_vm.listQuery, "offset", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery, "limit", $event)},"pagination":_vm.fetchData}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }